<template>
	<div class="breadcrumb">
		<div class="breadcrumb__list breadcrumb-content">
			<router-link
				v-if="pages.first"
				class="breadcrumb__link"
				:to="{
					name: pages.first.srcName || 'MainRoute',
					params: { lang: $store.state.uiLanguage }
				}"
			>
				{{ pages.first.title || $t('general.home') }}
			</router-link>

			<or-icon
				v-if="pages.inner?.length"
				type="arrow"
				class="breadcrumb__icon-arrow"
			/>

			<or-skeleton v-if="isLoad" style="width: 150px; height: 20px" />
			<template v-else-if="pages.inner?.length">
				<template v-for="link in pages.inner" :key="link.srcName">
					<router-link
						class="breadcrumb__link"
						:to="{
							name: link.srcName,
							params: { lang: $store.state.uiLanguage, ...link.params }
						}"
					>
						{{ link.title }}
					</router-link>

					<or-icon type="arrow" class="breadcrumb__icon-arrow" />
				</template>
			</template>
			<or-icon v-else type="arrow" class="breadcrumb__icon-arrow" />

			<or-skeleton v-if="isLoad" style="width: 100px; height: 20px" />
			<span v-else>{{ pages.current }}</span>
		</div>

		<div v-if="isLike" class="breadcrumb-like">
			<LikeSvg
				class="brown breadcrumb-like__btn"
				:class="{ active: isFavorite }"
				@click="clickLike"
			/>
		</div>
	</div>
</template>

<script>
import LikeSvg from '@/components/svg/Like'

export default {
	name: 'Breadcrumb',
	props: {
		isLike: {
			type: Boolean,
			default: false
		},
		isLoad: {
			type: Boolean,
			default: false
		},
		info: {
			type: Object,
			default: () => ({})
		},
		pages: {
			type: Object,
			default: () => ({
				first: {
					srcName: '',
					title: ''
				},
				inner: [],
				current: ''
			})
		}
	},
	components: { LikeSvg },
	computed: {
		isFavorite() {
			return this.$store.state.favoriteList.find((el) => {
				return el === this.info.slug
			})
		}
	},
	methods: {
		clickLike() {
			let status = false

			if (!this.isFavorite) {
				status = true
			}

			let cardInfo = {
				slug: this.info.slug
			}
			cardInfo.edit = status
			this.$store.commit('editFavoriteList', cardInfo)
		}
	}
}
</script>

<style lang="scss">
.breadcrumb {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 20px;

	&__list {
		position: relative;
		display: flex;
		gap: 20px;
		justify-content: flex-start;
		align-items: center;
	}

	&__icon-arrow {
		position: relative;
		width: 12px;
		height: 12px;
		transform: rotate(90deg);

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 5px;
		}
	}

	&__link {
		color: $brown-main;
		text-decoration: none;
	}

	.breadcrumb-like {
		display: flex;
		justify-content: center;
		align-items: center;

		&__btn {
			cursor: pointer;
		}
	}
}
</style>
