<template>
	<div class="units">
		<div class="container">
			<div class="units__wrapper">
				<Breadcrumb :pages="pages" :is-load="loading" />
				<div class="units__body">
					<h1 class="units__title">
						{{ this.$t('web.units') }}
						<or-button
							:kind="isAllUnits ? 'main' : 'secondary'"
							@click="toggleUnits"
							class="units__toggler-units"
						>
							{{ $t('web.broker_unit_all_units') }}
						</or-button>
					</h1>
					<UnitsTable
						class="units__table"
						:units="list"
						@update="(evt) => get(false, evt)"
					/>
					<div class="units__table-mobile">
						<transition name="fade" mode="out-in">
							<div v-if="!loading" class="units__list">
								<UnitsItem
									v-for="item in list"
									:key="item.id"
									:content="item"
								/>
							</div>
							<div v-else class="units__list">
								<or-skeleton
									v-for="item in 12"
									:key="item"
									style="width: 100%; height: 90px; margin-bottom: 10px"
									class="skeleton-card__text"
								/>
							</div>
						</transition>
					</div>
					<div class="units__btns">
						<or-link
							:to="{
								name: 'CreateOffer',
								params: { lang: $store.state.uiLanguage }
							}"
						>
							{{ this.$t('web.create_new_offer') }}
						</or-link>
						<or-link
							:to="{
								name: 'BrokerSearch',
								params: { lang: $store.state.uiLanguage },
								query: { ...this.$route.query }
							}"
							kind="solid-border"
						>
							{{ this.$t('web.add_more_units') }}
						</or-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import UnitsItem from '@/modules/broker/components/Units/UnitsItem.vue'
import { createRequestBroker } from '@/api/requestBuilder'
import requestBrokerConfigs from '@/api/requestBrokerConfigs'
import debounce from 'lodash/debounce'
import UnitsTable from '@/modules/broker/components/Units/UnitsTable.vue'
import OrButton from '@/components/global/orButton.vue'

let _loadFiltersCall

export default {
	name: 'Units',
	components: { OrButton, UnitsItem, UnitsTable, Breadcrumb },
	data() {
		return {
			isAllUnits: false,
			loading: true,
			list: [],
			meta: {},
			infoProject: {},
			scrollElement: null,
			isSkeleton: true
		}
	},
	beforeMount() {
		createRequestBroker(requestBrokerConfigs.GETCheckActiveSubscription).then(
			(response) => {
				if (!response.response.is_active_subscription) {
					this.$router.push({
						name: 'BrokerProfile',
						params: { lang: this.$store.state.uiLanguage }
					})
					this.$store.commit('showNotif', {
						type: 'error',
						text: this.$t('notification.action_require_subscription')
					})
				} else {
					this.get()
					this.getProject()
				}
			}
		)
	},
	mounted() {
		this.scrollElement = document.getElementsByTagName('html')[0]

		this.handleDebouncedScroll = debounce(this.handleScroll, 100)
		window.addEventListener('scroll', this.handleDebouncedScroll, {
			passive: true
		})
	},
	methods: {
		toggleUnits() {
			this.isAllUnits = !this.isAllUnits

			this.get()
		},
		async handleScroll() {
			if (
				this.scrollElement?.scrollTop + this.scrollElement?.clientHeight >=
				this.scrollElement?.scrollHeight - 600
			) {
				if (this.loading) {
					return
				}

				// // Проверяем остались ли посты
				if (this.meta.current_page < this.meta.last_page) {
					this.get(true)
				}
			}
		},
		get(more, sortData = {}) {
			const timestamp = new Date()
			_loadFiltersCall = timestamp
			let payload = {}

			if (!this.isAllUnits) {
				payload = {
					...this.$route.query
				}
			}

			payload = {
				...payload,
				pr_slug: this.$route.params.pr_slug,
				per_page: 12,
				page: more ? this.meta?.current_page + 1 : this.meta?.current_page || 1,
				...sortData
			}

			createRequestBroker(requestBrokerConfigs.GETSearchUnits, {
				queryPayload: {
					...payload
				}
			})
				.then((result) => {
					if (timestamp === _loadFiltersCall) {
						if (!more) {
							this.list.length = 0
						}

						this.list.push(...result.data.items)
						this.meta = result.data.meta
					}
				})
				.finally(() => {
					this.loading = false
				})
		},
		getProject() {
			createRequestBroker(requestBrokerConfigs.GETInfoProject, {
				routerPayload: { slug: this.$route.params.pr_slug }
			}).then((result) => {
				this.infoProject = result.response
			})
		}
	},
	computed: {
		pages() {
			return {
				first: {
					title: 'Search',
					srcName: 'BrokerSearch'
				},
				inner: [
					{
						title: this.infoProject.title,
						srcName: 'BrokerProject',
						params: { slug: this.$route.params.pr_slug }
					}
				],
				current: 'Units'
			}
		}
	}
}
</script>

<style lang="scss">
.units {
	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 40px;
		padding-bottom: 60px;
	}
	&__title {
		display: flex;
		gap: 20px;
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: 120%;
		margin: 0;
	}
	&__toggler-units {
		padding: 5px 10px;
	}
	&__body {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	&__list {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	&__table-mobile {
		display: none;
	}
	&__btns {
		display: flex;
		gap: 10px;
		flex-wrap: wrap;
		position: sticky;
		bottom: 20px;
		& > a:last-child {
			max-width: 180px;
			width: 100%;
			background: white;
		}
		& > a:first-child {
			max-width: 320px;
			width: 100%;
		}
	}
	@media screen and (max-width: 1180px) {
		&__table {
			display: none;
			&-mobile {
				display: block;
			}
		}
	}
	@media screen and (max-width: 768px) {
		&__btns {
			bottom: 70px;
		}
	}
	@media screen and (max-width: 620px) {
		&__btns {
			flex-direction: column;
			& > a:last-child {
				max-width: 100%;
			}
			& > a:first-child {
				max-width: 100%;
			}
		}
	}
	@media screen and (max-width: 479px) {
		&__title {
			font-size: 20px;
		}
	}
}
</style>
