<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="30"
		height="30"
		viewBox="0 0 30 30"
		fill="none"
	>
		<path
			d="M3 3.75L11.25 1.5L21.75 2.25L21 7.5L27.75 8.25L28.5 18.75L26.25 27L10.5 28.5L3.75 26.25L2.25 21V15L3 3.75Z"
			fill="#C99D56"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.4375 0.9375C4.29536 0.9375 0.9375 4.29536 0.9375 8.4375V21.5625C0.9375 25.7047 4.29536 29.0625 8.4375 29.0625H21.5625C25.7047 29.0625 29.0625 25.7047 29.0625 21.5625V9.65967C29.0625 8.62027 28.6311 7.62745 27.8712 6.91823L22.5439 1.94604C21.8495 1.29795 20.935 0.9375 19.9852 0.9375H8.4375ZM2.8125 8.4375C2.8125 5.33091 5.33091 2.8125 8.4375 2.8125H19.6875V5.625C19.6875 7.69608 21.3664 9.375 23.4375 9.375H27.1658C27.1801 9.46861 27.1875 9.56377 27.1875 9.65967V21.5625C27.1875 24.6691 24.6691 27.1875 21.5625 27.1875H8.4375C5.33091 27.1875 2.8125 24.6691 2.8125 21.5625V8.4375ZM21.5625 5.625V3.59489L25.7466 7.5H23.4375C22.402 7.5 21.5625 6.66052 21.5625 5.625Z"
			fill="#B98024"
		/>
		<path
			d="M15 20.1562C14.4822 20.1562 14.0625 19.7365 14.0625 19.2188V15.9375H10.7812C10.2635 15.9375 9.84375 15.5178 9.84375 15C9.84375 14.4822 10.2635 14.0625 10.7812 14.0625H14.0625V10.7812C14.0625 10.2635 14.4822 9.84375 15 9.84375C15.5178 9.84375 15.9375 10.2635 15.9375 10.7812V14.0625H19.2188C19.7365 14.0625 20.1562 14.4822 20.1562 15C20.1562 15.5178 19.7365 15.9375 19.2188 15.9375H15.9375V19.2188C15.9375 19.7365 15.5178 20.1562 15 20.1562Z"
			fill="white"
		/>
	</svg>
</template>
