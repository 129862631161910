<template>
	<table class="units-table">
		<thead class="units-table__head">
			<tr>
				<th
					v-for="item in list"
					:key="item.key"
					role="button"
					class="units-table__head-item"
					:class="item.class"
				>
					<div>
						<span>{{ this.$t(item.title) }}</span>
					</div>
				</th>
			</tr>
		</thead>
		<tbody>
			<UnitsTableRow v-for="unit in units" :content="unit" :key="unit.id" />
		</tbody>
	</table>
</template>

<script>
import UnitsTableRow from '@/modules/broker/components/Units/UnitsTableRow.vue'

export default {
	name: 'UnitsTable',
	components: { UnitsTableRow },
	props: {
		units: Array
	},
	data() {
		return {
			currentValue: '',
			direction: false // true - asc/false - desc
		}
	},
	computed: {
		config() {
			return {
				UNIT_NUMBER: 'unit_number',
				ROOMS: 'beds_count',
				AREA: 'area',
				PRICE: 'price',
				FLAT_NUMBER: 'flat_number',
				FLOOR: 'floor',
				TITLE: 'title',
				TYPE: 'type',
				FURNISHED: 'furnished',
				HANDOVER: 'post_handover_term',
				PARKING: 'has_parking'
			}
		},
		configDirection() {
			return {
				1: 'asc',
				0: 'desc'
			}
		},
		list() {
			return [
				{ key: this.config.UNIT_NUMBER, title: 'web.units' },
				{ key: this.config.ROOMS, title: 'web.rooms' },
				{ key: this.config.AREA, title: 'web.size' },
				{ key: this.config.PRICE, title: 'web.price' },
				{
					key: this.config.FLAT_NUMBER,
					title: 'web.flatNumber'
				},
				{ key: this.config.FLOOR, title: 'web.floor' },
				{ key: this.config.TITLE, title: 'web.view' },
				{ key: this.config.TYPE, title: 'web.unit_type' },
				{ key: this.config.FURNISHED, title: 'web.furnished' },
				{ key: this.config.HANDOVER, title: 'web.handover' },
				{ key: this.config.PARKING, title: 'web.parking' }
			]
		}
	},
	methods: {
		isActiveDirection(field, direction) {
			return this.currentValue === field
				? this.configDirection[Number(this.direction)] === direction
				: false
		},
		async toggle(field) {
			// Если меняем поле сортировки, то обнуляем направление
			if (field !== this.currentValue) {
				this.direction = false
			} else {
				this.direction = !this.direction
			}

			this.currentValue = field

			// if (this.updateWithoutUrl) {
			this.$emit('update', {
				order_by: field,
				direction: this.direction ? 'asc' : 'desc'
			})
			// } else {
			// 	await this.$router.push({
			// 		query: {
			// 			...this.$route.query,
			// 			order_by: field,
			// 			direction: this.direction ? 'asc' : 'desc'
			// 		},
			// 		params: { lang: this.$store.state.uiLanguage }
			// 	})

			// 	this.$emit('update')
			// }
		}
	}
}
</script>

<style lang="scss">
.units-table {
	border-spacing: 0 10px;
	&__head-item {
		font-size: 12px;
		color: var(--gray-50, #808080);
		line-height: normal;
		cursor: pointer;
		padding: 0 5px 10px 0;
		font-weight: 400;
		& > div {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 5px;
		}
		&-icons {
			display: flex;

			i {
				&:last-child {
					transform: rotate(180deg);
				}

				&.active {
					color: #c99d56;
				}
			}
		}
	}
}
</style>
