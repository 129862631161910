<template>
	<div class="units-item">
		<div class="units-item__body">
			<div class="units-item__left">
				<div class="units-item__item units-item__item-number w-70">
					# {{ content.unit_number }}
				</div>
				<div class="units-item__item w-70">{{ $t('web.units_table_beds_count', {n: content.beds_count}) }}</div>
				<div class="units-item__item w-80">{{ square }} ft2</div>
				<div class="units-item__item units-item__item-price w-115">
					<span>{{ money.price.toLocaleString() }}</span> {{ money.symbol }}
				</div>
			</div>
			<div
				:class="{ active: isActive }"
				@click="activeToggle"
				class="units-item__show-more"
			>
				More detailed
				<i class="icon-arrow" />
			</div>
			<div :class="{ active: isActive }" class="units-item__right">
				<div class="units-item__item w-70">
					<span>{{ $t('web.flatNumber') }}</span>
					{{ content.flat_number }}
				</div>
				<div class="units-item__item w-50">
					<span>{{ $t('web.floor') }}</span>
					{{ content.floor }}
				</div>
				<div class="units-item__item w-110">
					<span>{{ $t('web.view') }}</span>
					{{ content.view }}
				</div>
				<div class="units-item__item w-110">
					<span>{{ $t('web.unit_type') }}</span>
					{{ content.type }}
				</div>
				<div class="units-item__item units-item__item--furnished w-80">
					<span>{{ this.$t('web.furnished') }}</span>
					{{ content.furnished }}
				</div>
				<div class="units-item__item w-80">
					<span>{{ this.$t('web.handover') }}</span>
					{{ handoverYear }}
				</div>
				<div class="units-item__item w-70">
					<span>{{ this.$t('web.parking') }}</span>
					{{ content.has_parking ? 'Yes' : 'No' }}
				</div>
			</div>
			<div
				role="button"
				class="units-item__add"
				:class="{ added: isAdd }"
				@click="toggleUnit"
			>
				<Added v-if="isAdd" />
				<i v-else class="icon-add-pdf" />
				<span>
					{{ isAdd ? 'Added' : 'Add to offer' }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { money, simbol } from '@/enums/moneyСollections.js'
import Added from '@/components/svg/Added.vue'

export default {
	name: 'UnitsItem',
	components: { Added },
	props: {
		content: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			isActive: false
		}
	},
	computed: {
		isAdd() {
			return !!this.$store.state.broker.offer.units?.find(
				(itm) => itm.id === this.content.id
			)
		},
		handoverYear() {
			return this.content.post_handover_term
				? new Date(this.content.post_handover_term * 1000).getFullYear()
				: null
		},
		money() {
			return {
				price: this.content.price[money[this.$store.state.activeMoney]],
				symbol: simbol[this.$store.state.activeMoney]
			}
		},
		square() {
			return this.content.area[this.$store.state.areaUnit]
		}
	},
	methods: {
		addUnit() {
			this.$store.commit('addUnit', this.content)
		},
		removeUnit() {
			this.$store.commit('deleteUnit', this.content)
		},
		toggleUnit() {
			if (this.isAdd) {
				this.removeUnit()
			} else {
				this.addUnit()
			}
		},
		activeToggle() {
			this.isActive = !this.isActive
		}
	}
}
</script>
<style lang="scss">
.units-item {
	border-radius: 4px;
	border: 1px solid var(--color-stroke, #e5e5e5);
	padding: 17px 20px;
	display: flex;
	gap: 10px;
	justify-content: space-between;
	&__body {
		width: 100%;
		display: grid;
		gap: 20px;
		grid-template-columns: auto auto auto;
	}
	&__right,
	&__left {
		display: flex;
		align-items: center;
		gap: 10px;
	}
	&__right {
		margin-left: 20px;
	}
	&__item {
		font-size: 12px;
		font-weight: 400;
		line-height: normal;
		text-align: center;
		display: flex;
		flex-direction: column;
		gap: 4px;
		span {
			white-space: nowrap;
			margin: 0;
			font-size: 12px;
			line-height: normal;
			color: var(--secondary-1, #808080);
		}
		&-number {
			font-size: 14px;
			font-weight: 700;
		}
		&--furnished {
			text-transform: capitalize;
		}
		&-price {
			white-space: nowrap;
			flex-direction: row;
			span {
				color: var(--primary-1, #c99d56);
			}
		}
	}
	&__add {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		gap: 5px;
		cursor: pointer;
		i {
			font-size: 30px;
			color: #c99d56;
		}
		&.added {
			i {
				fill: #c99d56;
			}
			span {
				color: var(--text-1, #000);
				font-weight: 700;
			}
		}
		& span {
			white-space: nowrap;
			margin: 5px 0 0 0;
			font-size: 12px;
			line-height: normal;
			text-align: end;
			color: var(--secondary-1, #808080);
		}
	}
	&__show-more {
		display: none;
		color: var(--primary-1, #c99d56);
		font-size: 12px;
		font-weight: 700;
		line-height: normal;
		align-items: center;
		gap: 5px;
		transition: all 0.3s ease;
		.icon-arrow:before {
			font-size: 6px;
			color: #000000;
			transition: all 0.3s ease;
		}
	}
	@media screen and (max-width: 1180px) {
		&__body {
			grid-template-columns: 1fr auto;
			gap: 20px;
		}
		&__add {
			grid-row: 1 / 3;
			grid-column: 2 / 3;
		}
		&__right {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
			margin-left: 0;
			flex-wrap: wrap;
		}
		&__item {
			text-align: start;
		}
	}
	@media screen and (max-width: 800px) {
		&__right,
		&__left {
			gap: 20px;
		}
	}
	@media screen and (max-width: 580px) {
		&__show-more {
			display: flex;
			padding: 10px 0 0 0;
			&.active {
				.icon-arrow:before {
					transform: rotate(180deg);
				}
			}
		}
		&__body {
			gap: 10px;
			row-gap: 0;
		}
		&__left {
			align-self: end;
			flex-wrap: wrap;
			row-gap: 5px;
			column-gap: 10px;
		}
		&__right {
			row-gap: 20px;

			column-gap: 14px;
			flex-wrap: wrap;
			& .units-item__item {
				min-width: 75px !important;
			}
		}
		&__item-number {
			width: 100%;
		}
		&__right {
			grid-column: 1 / -1;
			grid-row: 3 / 4;
			margin-left: 0;
			opacity: 0;
			height: 0;
			transition: padding-top 0.3s ease;
			align-items: flex-start;
			&.active {
				height: auto;
				transition: all 0.3s ease;
				padding-top: 10px;
				opacity: 1;
			}
		}
	}
	@media screen and (max-width: 479px) {
		border-color: var(--color-stroke, #e5e5e5);
		border-width: 0 0 1px 0;
		padding: 16px 0;
	}
	// utility
	& .w-70 {
		min-width: 70px;
		@media screen and (max-width: 800px) {
			min-width: unset;
		}
	}
	& .w-80 {
		min-width: 80px;
		@media screen and (max-width: 800px) {
			min-width: unset;
		}
	}
	& .w-50 {
		min-width: 50px;
		@media screen and (max-width: 800px) {
			min-width: unset;
		}
	}
	& .w-115 {
		min-width: 115px;
		@media screen and (max-width: 800px) {
			min-width: unset;
		}
	}
	& .w-110 {
		min-width: 110px;
		@media screen and (max-width: 800px) {
			min-width: unset;
		}
	}
}
</style>
