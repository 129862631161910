<template>
	<tr>
		<td class="ut-row ut-row-number"># {{ content.unit_number }}</td>
		<td class="ut-row">{{ $t('web.units_table_beds_count', {n: content.beds_count}) }}</td>
		<td class="ut-row">{{ square }} ft2</td>
		<td class="ut-row ut-row-price">
			<span>{{ money.price.toLocaleString() }}</span> {{ money.symbol }}
		</td>
		<td class="ut-row">{{ content.flat_number }}</td>
		<td class="ut-row">{{ content.floor }}</td>
		<td class="ut-row">{{ content.view }}</td>
		<td class="ut-row">{{ content.type }}</td>
		<td class="ut-row ut-row--furnished">
			{{ content.furnished }}
		</td>
		<td class="ut-row">{{ handoverYear }}</td>
		<td class="ut-row">{{ content.has_parking ? 'Yes' : 'No' }}</td>
		<td class="ut-row ut-row__add" :class="{ added: isAdd }">
			<div role="button" @click="toggleUnit">
				<Added v-if="isAdd" />
				<i v-else class="icon-add-pdf" />
				<p>
					{{ isAdd ? 'Added' : 'Add to offer' }}
				</p>
			</div>
		</td>
	</tr>
</template>

<script>
import { money, simbol } from '@/enums/moneyСollections.js'
import Added from '@/components/svg/Added.vue'

export default {
	name: 'UnitsTableRow',
	components: { Added },
	props: {
		content: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			isActive: false
		}
	},
	computed: {
		isAdd() {
			return !!this.$store.state.broker.offer.units?.find(
				(itm) => itm.id === this.content.id
			)
		},

		handoverYear() {
			return this.content.post_handover_term
				? new Date(this.content.post_handover_term * 1000).getFullYear()
				: null
		},
		money() {
			return {
				price: this.content.price[money[this.$store.state.activeMoney]],
				symbol: simbol[this.$store.state.activeMoney]
			}
		},
		square() {
			return this.content.area[this.$store.state.areaUnit]
		}
	},
	methods: {
		addUnit() {
			this.$store.commit('addUnit', this.content)
		},
		removeUnit() {
			this.$store.commit('deleteUnit', this.content)
		},
		toggleUnit() {
			if (this.isAdd) {
				this.removeUnit()
			} else {
				this.addUnit()
			}
		},
		activeToggle() {
			this.isActive = !this.isActive
		}
	}
}
</script>

<style lang="scss">
.ut-row {
	font-size: 12px;
	font-weight: 400;
	line-height: normal;
	text-align: center;
	padding: 17px 5px;
	border-top: 1px solid var(--color-stroke, #e5e5e5);
	border-bottom: 1px solid var(--color-stroke, #e5e5e5);
	&:first-child {
		border-left: 1px solid var(--color-stroke, #e5e5e5);
		border-radius: 4px 0 0 4px;
		padding-left: 20px;
	}
	&:last-child {
		border-right: 1px solid var(--color-stroke, #e5e5e5);
		border-radius: 0 4px 4px 0;
		padding-right: 20px;
	}
	span {
		display: none;
	}
	&-number {
		font-size: 14px;
		font-weight: 700;
	}
	&--furnished {
		text-transform: capitalize;
	}
	&-price {
		white-space: nowrap;
		span {
			display: unset;
			color: var(--primary-1, #c99d56);
		}
	}
	&__add {
		cursor: pointer;
		& > div {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}
		i {
			font-size: 30px;
			display: block;
			color: #c99d56;
		}
		p {
			white-space: nowrap;
			margin: 5px 0 0 0;
			font-size: 12px;
			line-height: normal;
			text-align: end;
			color: var(--secondary-1, #808080);
		}

		&.added {
			i {
				fill: #c99d56;
			}
			p {
				color: var(--text-1, #000);
				font-weight: 700;
			}
		}
	}
}
</style>
